<template>
  <div class="home">
    <div class="logo">
      <img src="../assets/header.png" style="max-width: 100%" />
    </div>
    <div class="container1">
      <div class="title1">CONOCE QUÉ TAN TECNOLÓGICO ES TU LOCAL</div>
    </div>
    <div class="title2">
      LOCALES <span style="color: #ff7701">AUMENTAN LA VENTA</span> EN MÁS DE
      <span style="color: #ff7701">25%</span> CON HERRAMIENTAS DIGITALES
    </div>
    <!-- <hr class="h-card-divider" /> -->
    <div class="column-container">
      <div class="subtitle1">
        Revisa este listado con las 10 herramientas digitales claves y descubre
        si estás usando todas las tecnologías disponibles para que
        <b>vendas más y atiendas mejor</b>:
      </div>
      <div class="orange-container">
        <i
          class="fas fa-check fa-2x"
          style="color: white !important; margin-right: 1rem"
        ></i>
        <div class="subtitle2">
          Gana 1 punto por cada funcionalidad que usen en tu local.
        </div>
      </div>
      <div class="makeTest">¡Haz el test!</div>
      <div class="arrow">
        <i
          class="fas fa-chevron-down fa-4x"
          style="color: #ff7701 !important"
        ></i>
      </div>
    </div>
    <div class="checkboxes">
      <div
        class="checkbox"
        v-for="(option, index) in options"
        v-bind:key="index"
      >
        <input
          type="checkbox"
          :name="index"
          :id="index"
          v-model="booleans[index]"
        />
        <label :for="index">{{ option }}</label>
      </div>
    </div>
    <div class="resultTitle"><b>¡Conoce tu resultado!</b></div>
    <div class="arrow">
      <i
        class="fas fa-chevron-down fa-4x"
        style="color: #ff7701 !important"
      ></i>
    </div>
    <div class="resultBox">
      <div class="resultado" v-if="resultado && position === 0">
        <span>0-2 puntos</span>
        <div class="text">
          Estás perdiendo tiempo y dinero, digitalizate HOY.
        </div>
      </div>
      <div class="resultado" v-if="resultado && position === 1">
        <span>3-5 puntos</span>
        <div class="text">Desaprovechas mucho potencial.</div>
      </div>
      <div class="resultado" v-if="resultado && position === 2">
        <span>6-8 puntos</span>
        <div class="text">
          La tecnología es tu fuerte y puedes seguir creciendo.
        </div>
      </div>
      <div class="resultado" v-if="resultado && position === 3">
        <span>9-10 puntos</span>
        <div class="text">¡Felicidades! Seguramente usas TOTEAT.</div>
      </div>
    </div>
    <div class="footer">
      <div class="footerTitle">
        Ya son <span style="color: #ff7701">más de 2.000 restaurantes</span> que
        se la juegan por dar la mejor experiencia a sus clientes.
      </div>
      <a class="demo" href="https://toteat.com/es-CL/contacto.html"
        >Pide tu demo</a
      >
      <div class="logos">
        <img src="../assets/Captura.png" style="max-width: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {},
  data() {
    return {
      options: [
        "Sistema de Punto de Venta (POS) en la nube.",
        "Reportería en tiempo real, multilocal y en cualquier lugar.",
        "Toma de pedidos desde la mesa con tablet o móvil.",
        "Integración de tu POS con las apps de delivery.",
        "Monitor de Cocina KDS.",
        "Inventario / Control de Stock.",
        "Autoatención de clientes.",
        "Pagos con QR y móviles.",
        "Boleta y Factura electrónica e integrada a tu POS.",
        "Sistema de Reservas en Línea.",
      ],
      booleans: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
      },
    };
  },
  computed: {
    resultado() {
      let resultado = 0;
      for (let i = 0; i < 10; i++) {
        if (this.booleans[i]) {
          resultado++;
        }
      }
      return resultado;
    },
    position() {
      if (this.resultado < 3) return 0;
      else if (this.resultado < 6) return 1;
      else if (this.resultado < 9) return 2;
      else return 3;
    },
  },
};
</script>
